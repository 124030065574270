import React from 'react'

const Logo = () => {
  return (
    <div className="logo-container">
      <svg 
        id="logo" 
        role="img"
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 924.29 445.5"
      >
          <path className="cls-1"  d="M252.51,382.47q-20.72-8.33-49.95-24.07-18.9-10.35-32.18-16.43a150.47,150.47,0,0,0-25.87-9.22l69.3-323.1q-11.72-.9-18-.9-36.92,0-73.8,16.88T61.48,69.73Q37.85,97,37.86,125.75q0,23.4,11.92,33.53T86,169.4c0-.6-1.06-3.07-3.15-7.42s-3.15-12.08-3.15-23.18q0-36,16.42-61T142.71,40.7L75.66,352.55A89.38,89.38,0,0,1,86,352.1a99.5,99.5,0,0,1,38,7.65,182.17,182.17,0,0,1,34.65,18.68q16,11,38.48,29,30.14,23.85,49.72,35.33t39.83,11.47q18.44,0,30.82-18.9t16-51.75a98.62,98.62,0,0,1-37.8,7.2A114.65,114.65,0,0,1,252.51,382.47Z" transform="translate(-37.86 -8.75)"/>
          <path className="cls-1"  d="M280.85,92.45A35,35,0,0,0,306.28,82.1a34,34,0,0,0,10.57-25.2,34.69,34.69,0,0,0-10.57-25.42A34.68,34.68,0,0,0,280.85,20.9a33.93,33.93,0,0,0-25.2,10.58A35,35,0,0,0,245.3,56.9a34.3,34.3,0,0,0,10.35,25.2A34.26,34.26,0,0,0,280.85,92.45Z" transform="translate(-37.86 -8.75)"/>
          <path className="cls-1"  d="M784.4,173.45A34.1,34.1,0,0,0,793,150.5q0-14-8.33-23.85t-22.72-9.9a52.42,52.42,0,0,0-26.78,7.2q-12.37,7.2-27.67,20.7l5.85-27.9H648.5L617.82,261.44q-5,17.38-16.12,28.34t-24.3,11q-9.9,0-14.18-3.82T559,284.15a55.7,55.7,0,0,1,1.8-14.4l28.8-135h27l3.6-18h-27l15.3-71.1-66.6,9-13.5,62.1H513.5l-3.6,18h14.85l-27,126.45h0q-11.26,10.35-32.4,22.49a67.78,67.78,0,0,0,1.8-17.1q0-12.15-2.25-39.15-5.4-67-7.65-124.65l-66.6,9Q379,149.15,365.9,181.33A573.45,573.45,0,0,1,335.3,245q-9.9,5-16.87,16t-7,25.87c0,.33,0,.66,0,1-.6.65-1.19,1.31-1.83,1.93q-11.25,11-24.3,11-9.9,0-14.17-3.82t-4.28-12.83a55.7,55.7,0,0,1,1.8-14.4l32.4-153H236.3L205.7,261.2q-3.58,18-3.6,29.25,0,27,13.28,40.5t34.87,13.5q25.65,0,51.75-17.33a74.67,74.67,0,0,0,15.77-14.33c.3.58.57,1.16.88,1.74q7.22,13.28,22.05,21.59t36.45,8.33a118.81,118.81,0,0,0,38.48-6.52,77.51,77.51,0,0,0,32.17-20.48,270.31,270.31,0,0,0,46.62-33.59q-.27,3.54-.27,6.59,0,27,13.27,40.5t34.88,13.5q25.65,0,51.75-17.33a71.31,71.31,0,0,0,12-10.14l-5.25,24.77h64.8l36-171q10.35-9.45,16.87-13.27t16-3.83q3.6,0,4.95,2.7a41.52,41.52,0,0,1,2.7,8.55q1.78,9,5.17,13.73t13.28,4.72Q775.84,183.35,784.4,173.45ZM390.65,299q-11.25,9.9-27.9,9.9-14.4,0-20.92-7.65c-3.41-4-5.48-9.75-6.21-17.24.58-1.25,1.16-2.52,1.74-3.82,2.2,2,5.55,3.06,10.09,3.06a17.71,17.71,0,0,0,14.18-6.75,23.25,23.25,0,0,0,5.62-15.3q0-10.35-10.35-15.75,25.65-49.5,36.45-85,1.35,17.55,5.85,68.85,2.7,24.3,2.7,37.8Q401.9,289.1,390.65,299Z" transform="translate(-37.86 -8.75)"/>
          <path className="cls-1"  d="M953.82,126.65q-8.34-9.9-22.73-9.9a52.41,52.41,0,0,0-26.77,7.2q-12.39,7.2-27.68,20.7l5.85-27.9h-64.8l-47.7,225h64.8l36-171q10.35-9.45,16.88-13.27t16-3.83q3.6,0,5,2.7a41.52,41.52,0,0,1,2.7,8.55q1.8,9,5.18,13.73t13.27,4.72q15.3,0,23.85-9.9a34.05,34.05,0,0,0,8.55-22.95Q962.14,136.55,953.82,126.65Z" transform="translate(-37.86 -8.75)"/>
      </svg>
    </div>
  )
}

export default Logo
